import React, { useState, useEffect } from "react"
import { navigate } from 'gatsby'
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import { useForm } from "react-hook-form"
import { Helmet } from "react-helmet"
import LayoutNoStripe from "../components/layoutNoStripe"
import {
  Section,
  SectionHeading,
  SectionHeaderLedeDiv,
} from "../styledComponents/section"
import {
  StyledForm,
  StyledFieldset,
  StyledInput,
  StyledLabel,
  StyledLabelHeader,
  StyledFormElementWrapper
} from "../styledComponents/contact"
import { StyledButton } from '../styledComponents/button'

const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)

const ForgotPassword = ( location ) => {
  const identity = useIdentityContext()
  const { register, handleSubmit, formState: { errors } } = useForm()
  const [formProcessing, setFormProcessing] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)

  const onSubmit = async (data) => {
    setFormProcessing(true)
    await identity
      .sendPasswordRecovery({ email: data.email })
      .catch(_ => _)
    setFormProcessing(false)
    setFormSubmitted(true)
  }

  // Current users don't need to confuse themselves
  useEffect(() => {
    identity.user && navigate('/my-account')
  }, [identity.user])

  return (
    <>
    <Helmet>
     <meta name={`robots`} content={`noindex, nofollow`} />x
   </Helmet>
    <LayoutNoStripe location={location}>
      <Section>
          <Heading level={1} align="center">
            Forgot Password
          </Heading>
          <SectionHeaderLedeDiv><p>Please enter the email address you registered with.</p></SectionHeaderLedeDiv>
          <StyledForm  onSubmit={handleSubmit(onSubmit)}>
                {formSubmitted ?
                  <div>
                    <p>Thanks! If that account exists, we'll send an email to reset the password.</p>
                  </div>
                  : <>
                      <StyledFieldset>
                        <StyledFormElementWrapper>
                          <StyledLabel htmlFor="email">
                            <StyledLabelHeader
                              required
                              isEmpty={errors.email}
                            >
                              Email
                            </StyledLabelHeader>
                            <StyledInput
                              id="email"
                              name="email"
                              type="text"
                              required
                              {...register('email', { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                            className={`${formProcessing && 'disabled'}`}
                            />
                          </StyledLabel>
                          {errors.email ? (
                            <span className="error-message">Email is required, use correct format</span>
                          ) : null}
                        </StyledFormElementWrapper>
                      </StyledFieldset>
                    
                      <StyledButton
                        className={`bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${formProcessing && 'opacity-50 cursor-not-allowed'}`}
                        type="submit">
                        Reset
                      </StyledButton>
                  </>
                }
          </StyledForm>
        </Section>
    </LayoutNoStripe>
    </>
  )
}
export default ForgotPassword